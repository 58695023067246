<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <i class="el-icon-s-home"></i>
          首页
        </el-breadcrumb-item>
        <el-breadcrumb-item>空桶统计</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <div
      style="display: flex; align-items: center; padding: 20px; font-size: 14px"
    >
      <el-row>
        <el-col
          :span="7"
          style="display: flex; align-items: center; white-space: nowrap"
        >
          <span>完成时间：</span>
          <el-date-picker
            type="date"
            v-model="creatStartDate"
            :picker-options="creatOpt1"
          ></el-date-picker>
          <span style="margin: 0 5px">-</span>
          <el-date-picker
            type="date"
            v-model="creatEndDate"
            :picker-options="creatOpt2"
          ></el-date-picker>
        </el-col>
        <el-col
          :span="5"
          style="display: flex; align-items: center; white-space: nowrap"
        >
          <span>选择水站：</span>
          <el-select
            v-model="option1"
            clearable
            filterable
            placeholder="选择水站"
          >
            <el-option
              v-for="item in option1es"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12" style="display: flex; align-items: center">
          <el-input
            v-model="QueryContent"
            style="width: 45%; margin: 0 5px"
            placeholder="输入品牌名称"
          ></el-input>
          <el-button type="success" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span>查询</span>
            </div>
          </el-button>
          <el-button type="success" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span>清楚</span>
            </div>
          </el-button>
          <el-button type="primary" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span>导出报表</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div style="padding: 20px">
      <table class="table">
        <tr>
          <th v-for="(item, index) in tableth" :key="index">{{ item }}</th>
        </tr>
        <tr v-for="(item, index) in tabletd" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.brand }}</td>
          <td>{{ item.give }}</td>
          <td>{{ item.storage }}</td>
          <td>{{ item.exchange }}</td>
          <td>{{ item.ratio1 }}</td>
          <td>{{ item.ratio2 }}</td>
          <td>{{ item.ratio3 }}</td>
          <td>{{ item.cask }}</td>
          <td>{{ item.backbucket }}</td>
          <td>{{ item.underbarrel }}</td>
          <td>{{ item.returnBucket }}</td>
          <td>{{ item.buyBarrel }}</td>
          <td
            style="
              padding: 10px 20px;
              border: 0;
              border-bottom: 1px solid #d0cfd1;
              border-right: 1px solid #d0cfd1;
            "
          >
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span style="margin: 0 10px"></span>
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
          </td>
        </tr>
        <tr v-if="this.tabletd.length == 0">
          <td :colspan="13" style="color: #666">没有数据</td>
        </tr>
        <tr>
          <td>合计</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <div class="tableBottom">
        <span v-if="isResult" style=""
          >显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >
        <span v-if="!isResult" style=""
          >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      creatStartDate: "",
      creatEndDate: "",
      creatOpt1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      creatOpt2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.creatStartDate.getTime()
          );
        },
      },
      finishStartDate: "",
      finishEndDate: "",
      finishOpt1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      finishOpt2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.finishStartDate.getTime()
          );
        },
      },

      QueryContent: "",
      option1: "",
      option1es: [],

      tableth: [
        "水站名称",
        "品牌名称",
        "送出",
        "入库",
        "兑换桶",
        "1比1",
        "2比1",
        "3比1",
        "押桶",
        "退桶",
        "水站欠桶",
        "买桶",
        "详情",
      ],
      tabletd: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tabletd.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tabletd.length;
    }
  },
  computed: {
    totalNum() {
      if (this.tabletd.length === 0) {
        return " ";
      } else {
        return this.tabletd.reduce((acc, cur) => acc + cur.number, 0);
      }
    },
    totalCost() {
      if (this.tabletd.length === 0) {
        return " ";
      } else {
        return this.tabletd.reduce((acc, cur) => acc + cur.purchasingCost, 0);
      }
    },
    totalMoney() {
      if (this.tabletd.length === 0) {
        return " ";
      } else {
        return this.tabletd.reduce((acc, cur) => acc + cur.money, 0);
      }
    },
  },
  methods: {
    reload() {
      location.reload();
    },
    handleEdit() {},
    handleDelete() {},
    // arraySpanMethod({ rowIndex, columnIndex }) {
    //   if (rowIndex === this.tableData.length - 1) {
    //     if (columnIndex === 0) {
    //       return [1, 2];
    //     } else if (columnIndex === 1) {
    //       return [0, 0];
    //     }
    //   }
    // },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;

  th {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }

  td {
    padding: 10px 20px;
    border: 1px solid #e5e5e5;
  }
}
</style>
